<template>
  <v-container
    id="login"
    class="fill-height justify-center"
    tag="section"
  >
    <v-row justify="center">
      <v-slide-y-transition appear>
        <base-material-card
          color="success"
          light
          max-width="100%"
          width="400"
          class="px-5 py-3"
        >
          <template v-slot:heading>
            <div class="text-center">
              <h1 class="display-2 font-weight-bold mb-2">
                Login
              </h1>
            </div>
          </template>

          <v-card-text class="text-center">
            <v-text-field
              v-model="username"
              color="secondary"
              label="Username"
              prepend-icon="mdi-face"
              class="mt-10"
            />

            <v-text-field
              v-model="password"
              class="mb-8"
              type="password"
              color="secondary"
              label="Password"
              prepend-icon="mdi-lock-outline"
            />

            <pages-btn
              large
              color=""
              depressed
              class="v-btn--text success--text"
              @click="validateLogin()"
            >
              Submit
            </pages-btn>
          </v-card-text>
        </base-material-card>
      </v-slide-y-transition>
    </v-row>
  </v-container>
</template>

<script>
  import axios from 'axios'
  import router from '@/router'
  const API_PATH = 'https://mps2-backend.herokuapp.com/api'

  export default {
    name: 'PagesLogin',

    components: {
      PagesBtn: () => import('./components/Btn'),
    },

    data: () => ({
      socials: [
        {
          href: '#',
          icon: 'mdi-facebook-box',
        },
        {
          href: '#',
          icon: 'mdi-twitter',
        },
        {
          href: '#',
          icon: 'mdi-github-box',
        },
      ],
      statut: 'Student',
      status_error: false,
      error_message: '',
      status_success: false,
      success_message: '',
      dialog: true,
      tab: 0,
      tabs: [
        { name: 'Login', icon: 'mdi-account' },
        { name: 'Register', icon: 'mdi-account-outline' },
      ],
      valid: true,
      firstName: '',
      lastName: '',
      username: '',
      password: '',
      password_verify: '',
      loginPassword: '',
      loginUsername: '',
      show1: false,
      items: ['Student', 'Profesor', 'Admin'],
      rules: {
        required: (value) => !!value || 'Required.',
        min: (v) => (v && v.length >= 5) || 'Min 5 characters',
        max: (v) => (v && v.length <= 20) || 'Max 20 characters',
      },
    }),

    mounted () {
      this.$store.commit('SET_USER', null)
      this.$store.commit('SET_LEVEL', 0)
    },

    methods: {
      validateLogin () {
        axios
          .get(API_PATH + '/authUser?username=' + this.username + '&password=' + this.password)
          .then((res) => {
            this.success_message = res.data.status
            if (this.success_message === 'succes') {
              this.status_success = true
              this.$store.commit('SET_USER', this.username)
              this.$store.commit('SET_LEVEL', res.data.permLevel)
              router.push('/')
            }
          })
          .catch((error) => {
            this.error_message = error.response.data.message
            this.status_error = true
          })
      },
    },
  }
</script>
